var __require = /* @__PURE__ */ ((x) => typeof require !== "undefined" ? require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof require !== "undefined" ? require : a)[b]
}) : x)(function(x) {
  if (typeof require !== "undefined")
    return require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});

// src/types/product-config-map-types.ts
var PartnerIdentifier = /* @__PURE__ */ ((PartnerIdentifier6) => {
  PartnerIdentifier6["ATT"] = "att";
  PartnerIdentifier6["Acrisure"] = "acrisure";
  PartnerIdentifier6["Asurion"] = "asurion";
  PartnerIdentifier6["Comparion"] = "comparion";
  PartnerIdentifier6["Cricket"] = "cricket";
  PartnerIdentifier6["GameStop"] = "gamestop";
  PartnerIdentifier6["Google"] = "google";
  PartnerIdentifier6["Mcafee"] = "mcafee";
  PartnerIdentifier6["Microsoft"] = "microsoft";
  PartnerIdentifier6["RealPage"] = "real-page";
  PartnerIdentifier6["Staples"] = "staples";
  PartnerIdentifier6["VIU"] = "viu";
  PartnerIdentifier6["Verizon"] = "verizon";
  return PartnerIdentifier6;
})(PartnerIdentifier || {});
var ProductIdentifier = /* @__PURE__ */ ((ProductIdentifier5) => {
  ProductIdentifier5["ATTHomeTech"] = "home-tech-protect";
  ProductIdentifier5["ATTMobileInsurance"] = "mobile-insurance";
  ProductIdentifier5["ATTPrePaid"] = "mobile-protection";
  ProductIdentifier5["AppliancePlus"] = "appliance-plus";
  ProductIdentifier5["ApplianceProtect"] = "appliance-protect";
  ProductIdentifier5["HomeDeviceProtect"] = "home-device-protect";
  ProductIdentifier5["HomeEntertainment"] = "home-entertainment";
  ProductIdentifier5["HomePlus"] = "home-plus";
  ProductIdentifier5["Protect"] = "protect";
  ProductIdentifier5["RenterTechProtect"] = "renter-tech-protect";
  ProductIdentifier5["RenterTechSupport"] = "renter-tech-support";
  return ProductIdentifier5;
})(ProductIdentifier || {});
var PresentationIdentifier = /* @__PURE__ */ ((PresentationIdentifier5) => {
  PresentationIdentifier5["Affiliate"] = "affiliate";
  PresentationIdentifier5["App"] = "app";
  PresentationIdentifier5["Gift"] = "gift";
  PresentationIdentifier5["PayTermsEnroll"] = "pay-terms-enroll";
  PresentationIdentifier5["PrePaid"] = "prepaid";
  PresentationIdentifier5["Terms"] = "terms";
  return PresentationIdentifier5;
})(PresentationIdentifier || {});
var InternalPartner = /* @__PURE__ */ ((InternalPartner2) => {
  InternalPartner2["ATT"] = "ATT";
  InternalPartner2["ATTPrepaid"] = "ATTPrepaid";
  InternalPartner2["Asurion"] = "Asurion";
  InternalPartner2["ClaroBrazil"] = "Claro Brazil";
  InternalPartner2["Cricket"] = "Cricket";
  InternalPartner2["GameStop"] = "GameStop";
  InternalPartner2["Google"] = "Google";
  InternalPartner2["Mcafee"] = "Mcafee";
  InternalPartner2["Microsoft"] = "Microsoft";
  InternalPartner2["Optimum"] = "Optimum";
  InternalPartner2["Staples"] = "Staples";
  InternalPartner2["Verizon"] = "Verizon";
  return InternalPartner2;
})(InternalPartner || {});
var CustomerSessionPartner = /* @__PURE__ */ ((CustomerSessionPartner5) => {
  CustomerSessionPartner5["ATT"] = "att";
  CustomerSessionPartner5["Amazon"] = "amazon";
  CustomerSessionPartner5["Asurion"] = "asurion";
  CustomerSessionPartner5["AsurionHome"] = "asurionhome+";
  CustomerSessionPartner5["Cricket"] = "cricket";
  CustomerSessionPartner5["Google"] = "google";
  CustomerSessionPartner5["Mcafee"] = "mcafee";
  CustomerSessionPartner5["Microsoft"] = "microsoft";
  CustomerSessionPartner5["Optimum"] = "optimum";
  CustomerSessionPartner5["Staples"] = "staples";
  CustomerSessionPartner5["Verizon"] = "verizon";
  return CustomerSessionPartner5;
})(CustomerSessionPartner || {});
var BasePath = /* @__PURE__ */ ((BasePath3) => {
  BasePath3["ATTHomeTech"] = "home-tech-protect";
  BasePath3["ATTPrePaid"] = "mobile-protection";
  BasePath3["Affiliate"] = "affiliate";
  BasePath3["AppliancePlus"] = "appliance-plus";
  BasePath3["ApplianceProtect"] = "appliance-protect";
  BasePath3["Bundle"] = "bundle";
  BasePath3["HomeDeviceProtect"] = "home-device-protect";
  BasePath3["HomeEntertainment"] = "home-entertainment";
  BasePath3["HomePlus"] = "home-plus";
  BasePath3["Protect"] = "protect";
  BasePath3["RenterTechProtect"] = "renter-tech-protect";
  BasePath3["RenterTechSupport"] = "renter-tech-support";
  return BasePath3;
})(BasePath || {});
var ProductType = /* @__PURE__ */ ((ProductType3) => {
  ProductType3["Accessories"] = "Accessories";
  ProductType3["Bundle"] = "Bundle";
  ProductType3["ConnectedHome"] = "ConnectedHome";
  ProductType3["Internet"] = "Internet";
  ProductType3["Mobility"] = "Mobility";
  ProductType3["PC"] = "PC";
  return ProductType3;
})(ProductType || {});
var Channel = /* @__PURE__ */ ((Channel2) => {
  Channel2["Affiliate"] = "Affiliate";
  Channel2["Chat"] = "Chat";
  Channel2["Claims"] = "Claims";
  Channel2["CustomerAPI"] = "Customer API";
  Channel2["DirectToConsumer"] = "DirectToConsumer";
  Channel2["Retail"] = "Retail";
  Channel2["Voice"] = "Voice";
  return Channel2;
})(Channel || {});
var Environment = /* @__PURE__ */ ((Environment5) => {
  Environment5["NonProd"] = "NonProd";
  Environment5["Prod"] = "Prod";
  return Environment5;
})(Environment || {});
var EligibilityStrategy = /* @__PURE__ */ ((EligibilityStrategy4) => {
  EligibilityStrategy4["CustomerSessionInitiated"] = "CustomerSessionInitiated";
  EligibilityStrategy4["External"] = "External";
  EligibilityStrategy4["None"] = "None";
  EligibilityStrategy4["ProductOfferInitiated"] = "ProductOfferInitiated";
  return EligibilityStrategy4;
})(EligibilityStrategy || {});

// src/product-config-map.ts
var PRODUCT_CONFIG_MAP = {
  "3002": {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-device-protect" /* HomeDeviceProtect */,
          partnerIdentifier: "verizon" /* Verizon */,
          presentationIdentifier: "terms" /* Terms */,
          productIdentifier: "home-device-protect" /* HomeDeviceProtect */,
          steps: [
            {
              page: "legal-disclosures"
            },
            {
              page: "legal-disclosures/sms-terms"
            },
            {
              page: "legal-disclosures/confirmation"
            },
            {
              page: "legal-disclosures/declination"
            }
          ]
        }
      },
      ["Voice" /* Voice */]: {
        customerSessionPartner: "verizon" /* Verizon */,
        productOfferPageRoute: "vhdp/offer",
        productSelectCardConfig: {
          price: "$25/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-VZW-ASP-VHDP",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-VZW-ASP-VHDP"
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    internalPartner: "Verizon" /* Verizon */,
    isCustomerActionEnabledInProd: true,
    name: "Verizon Home Device Protect",
    price: "25.00",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  ACRISUREAPPLPLUS: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "affiliate" /* Affiliate */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          productIdentifier: "appliance-plus" /* AppliancePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "payment",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ACRISURE_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Appliance Plus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance+",
    price: "34.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  ACRISUREAPPLPLUSDIRECT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-plus" /* AppliancePlus */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "appliance-plus" /* AppliancePlus */,
          steps: [
            {
              page: "personal-info",
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "program-info",
              page: "review",
              requiredUrlParams: ["a_id"]
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ACRISURE_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance+",
    price: "34.99",
    productSkuOverride: "ACRISUREAPPLPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  ACRISUREHOMEPLUS: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "affiliate" /* Affiliate */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "payment",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ACRISURE_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  ACRISUREHOMEPLUSDIRECT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "payment",
              page: "program-info",
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "program-info",
              page: "review",
              requiredUrlParams: ["a_id"]
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ACRISURE_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "ACRISUREHOMEPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  APPHOMEPLUSANNUAL360: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "asurion" /* Asurion */,
        productOfferPageRoute: "appliance-plus/offer",
        productSelectCardConfig: {},
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "",
          // TODO
          ["Prod" /* Prod */]: ""
          // TODO
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance+",
    price: "29.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  APPLHOMEPLUS30: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "asurion" /* Asurion */,
        productOfferPageRoute: "appliance-plus/offer",
        productSelectCardConfig: {},
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "",
          // TODO
          ["Prod" /* Prod */]: ""
          // TODO
        }
      },
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-plus" /* AppliancePlus */,
          partnerIdentifier: "asurion" /* Asurion */,
          presentationIdentifier: "affiliate" /* Affiliate */,
          productIdentifier: "appliance-plus" /* AppliancePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance+",
    price: "29.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  APPLHOMEPLUS35_99: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Appliance Plus"
        },
        customerSessionPartner: "asurion" /* Asurion */,
        productOfferPageRoute: "appliance-plus/offer",
        productSelectCardConfig: {
          price: "$34.99/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-ASU-ASP-APPPLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-ASU-ASP-APPPLUS"
        }
      },
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-plus" /* AppliancePlus */,
          partnerIdentifier: "asurion" /* Asurion */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "appliance-plus" /* AppliancePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance+",
    price: "34.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  ASURIONCOMPLETEPROTECT: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|Asurion Home+"
        },
        customerSessionPartner: "amazon" /* Amazon */,
        productOfferPageRoute: "amazon-complete-protect/offer",
        productSelectCardConfig: {
          price: "$16.99/month",
          priority: 0
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-AMZ-ASP-ACP",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-AMZ-ASP-ACP"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Complete Protect",
    price: "16.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  ATT_FIBER: {
    channelConfigs: {},
    clientChannelId: "",
    internalPartner: "ATT" /* ATT */,
    name: "",
    price: "",
    productType: "Internet" /* Internet */
  },
  CA_APPLPLUS_ACRISURE_PAYTERMSENROLL: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-plus" /* AppliancePlus */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          presentationIdentifier: "pay-terms-enroll" /* PayTermsEnroll */,
          productIdentifier: "appliance-plus" /* AppliancePlus */,
          steps: [
            {
              backPage: void 0,
              page: "intro"
            },
            {
              backPage: "intro",
              page: "program-info",
              urlParamsToRemove: []
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "confirmation",
              urlParamsToRemove: []
            }
          ]
        },
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Appliance Plus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    isCustomerActionEnabledInProd: false,
    name: "Asurion Appliance+",
    price: "34.99",
    productSkuOverride: "ACRISUREAPPLPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  CA_HOMEPLUS_ACRISURE__PAYTERMSENROLL: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          presentationIdentifier: "pay-terms-enroll" /* PayTermsEnroll */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              backPage: void 0,
              page: "intro"
            },
            {
              backPage: "intro",
              page: "program-info",
              urlParamsToRemove: []
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "confirmation",
              urlParamsToRemove: []
            }
          ]
        },
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    isCustomerActionEnabledInProd: false,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "ACRISUREHOMEPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  CA_HOMEPLUS_GOOGLE_PAYTERMSENROLL: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "google" /* Google */,
          presentationIdentifier: "pay-terms-enroll" /* PayTermsEnroll */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              backPage: void 0,
              page: "intro"
            },
            {
              backPage: "intro",
              page: "program-info",
              urlParamsToRemove: []
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "confirmation",
              urlParamsToRemove: []
            }
          ]
        },
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|Google Complete Home Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    isCustomerActionEnabledInProd: true,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "GOOGLEHOMEPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  CA_MICROSOFTHOMEPILOT_PAYTERMSENROLL: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "microsoft" /* Microsoft */,
          presentationIdentifier: "pay-terms-enroll" /* PayTermsEnroll */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              backPage: void 0,
              page: "intro"
            },
            {
              backPage: "intro",
              page: "program-info",
              urlParamsToRemove: []
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "confirmation",
              urlParamsToRemove: []
            }
          ]
        },
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|Microsoft Complete Home Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    isCustomerActionEnabledInProd: false,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "MICROSOFTHOMEPILOT",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  COMPARIONAPPPROTECT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "affiliate" /* Affiliate */,
          partnerIdentifier: "comparion" /* Comparion */,
          productIdentifier: "appliance-protect" /* ApplianceProtect */,
          steps: [
            {
              page: "personal-info"
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "payment",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "COMPARION_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion Appliance Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance Protect",
    price: "34.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  COMPARIONAPPPROTECTDIRECT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-protect" /* ApplianceProtect */,
          partnerIdentifier: "comparion" /* Comparion */,
          presentationIdentifier: "affiliate" /* Affiliate */,
          productIdentifier: "appliance-protect" /* ApplianceProtect */,
          steps: [
            {
              page: "personal-info"
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "COMPARION_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion Appliance Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance Protect",
    price: "34.99",
    productSkuOverride: "COMPARIONAPPPROTECT",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  COMPARIONAPPPROTECTGIFT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-protect" /* ApplianceProtect */,
          partnerIdentifier: "comparion" /* Comparion */,
          presentationIdentifier: "gift" /* Gift */,
          productIdentifier: "appliance-protect" /* ApplianceProtect */,
          steps: [
            {
              page: "personal-account-details"
            },
            {
              backPage: "personal-account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "payment",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "COMPARION_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion Appliance Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance Protect",
    price: "34.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  COMPARIONHOMEPLUS: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "affiliate" /* Affiliate */,
          partnerIdentifier: "comparion" /* Comparion */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info"
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "payment",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "COMPARION_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  COMPARIONHOMEPLUSDIRECT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "comparion" /* Comparion */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review",
              requiredUrlParams: ["a_id"]
            },
            {
              backPage: "payment",
              page: "confirmation"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "COMPARION_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "COMPARIONHOMEPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  CRICKETHOMEPLUS: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "CRICKETHOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "cricket" /* Cricket */,
        productOfferPageRoute: "cricket/offer",
        productSelectCardConfig: {
          price: "$24.99/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-CRK-ASP-HOME_PLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-CRK-ASP-HOME_PLUS"
        }
      },
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "cricket" /* Cricket */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Cricket" /* Cricket */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  CUSTOMERPAY_ACRISURE_APPLPLUS: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-plus" /* AppliancePlus */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          presentationIdentifier: "pay-terms-enroll" /* PayTermsEnroll */,
          productIdentifier: "appliance-plus" /* AppliancePlus */,
          steps: [
            {
              backPage: void 0,
              page: "intro"
            },
            {
              backPage: "intro",
              page: "program-info",
              urlParamsToRemove: []
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "confirmation",
              urlParamsToRemove: []
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ACRISURE_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Appliance Plus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    isCustomerActionEnabledInProd: false,
    name: "Asurion Appliance+",
    price: "34.99",
    productSkuOverride: "ACRISUREAPPLPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  CUSTOMERPAY_ACRISURE_HOMEPLUS: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "acrisure" /* Acrisure */,
          presentationIdentifier: "pay-terms-enroll" /* PayTermsEnroll */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              backPage: void 0,
              page: "intro"
            },
            {
              backPage: "intro",
              page: "program-info",
              urlParamsToRemove: []
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "confirmation",
              urlParamsToRemove: []
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ACRISURE_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    isCustomerActionEnabledInProd: false,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "ACRISUREHOMEPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  FIOS: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        customerSessionPartner: "verizon" /* Verizon */,
        productOfferPageRoute: "vzn-fios-standard/offer",
        productSelectCardConfig: {
          price: "$25/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-VZW-ASP-FIOS_STANDARD",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-VZW-ASP-FIOS_STANDARD"
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    eligibilityStrategy: "CustomerSessionInitiated" /* CustomerSessionInitiated */,
    internalPartner: "Verizon" /* Verizon */,
    name: "Fios Internet Standard",
    price: "25.00",
    productType: "Internet" /* Internet */
  },
  FWA_4G: {
    channelConfigs: {},
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    eligibilityStrategy: "CustomerSessionInitiated" /* CustomerSessionInitiated */,
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon 4G Internet",
    price: "25.00",
    productType: "Internet" /* Internet */
  },
  FWA_5G_CBAND: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        customerSessionPartner: "verizon" /* Verizon */,
        productOfferPageRoute: "vz5g/offer",
        productSelectCardConfig: {
          price: "$25/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-VZW-ASP-V5G",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-VZW-ASP-V5G"
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    eligibilityStrategy: "CustomerSessionInitiated" /* CustomerSessionInitiated */,
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon 5G Internet",
    price: "25.00",
    productType: "Internet" /* Internet */,
    productVariants: ["FWA_5G_UWB"]
  },
  FWA_5G_UWB: {
    channelConfigs: {},
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    eligibilityStrategy: "CustomerSessionInitiated" /* CustomerSessionInitiated */,
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon 5G Internet",
    price: "25.00",
    productType: "Internet" /* Internet */,
    productVariants: ["FWA_5G_CBAND"]
  },
  "GameStop Protect": {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "protect" /* Protect */,
          partnerIdentifier: "gamestop" /* GameStop */,
          steps: [
            {
              page: "personal-info"
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: [
                "csid",
                "poid",
                "vendor_client_account_number"
              ]
            },
            {
              backPage: "account-details",
              page: "send-smart-link",
              urlParamsToRemove: ["poid"]
            },
            {
              page: "smart-link-confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "GameStop-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|GameStop Protect"
        },
        contact: [
          {
            email: "storeops-optimization@gamestop.com",
            phone: "888-524-6494",
            type: "support"
          }
        ]
      }
    },
    clientChannelId: "03C11C03568648CAE0633A634C641724",
    dealerCode: "7780551",
    dealerName: "GAMESTOP",
    internalPartner: "Asurion" /* Asurion */,
    name: "GameStop Protect",
    price: "9.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  GOOGLEHOMEPLUS: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|Google Complete Home Protect"
        },
        customerSessionPartner: "google" /* Google */,
        productOfferPageRoute: "google-complete-protect/offer",
        productSelectCardConfig: {
          price: "$24.99/month",
          priority: 0
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-GGL-ASP-GOOGLEHOMEPLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-GGL-ASP-GOOGLEHOMEPLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  HOMEPLUS1000: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "asurionhome+" /* AsurionHome */,
        productOfferPageRoute: "asurion-home-plus/offer",
        productSelectCardConfig: {
          price: "$25/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "",
          ["Prod" /* Prod */]: ""
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */,
    promoConfig: {
      durationMonths: "3",
      price: "19.99"
    }
  },
  HOMEPLUS2000: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "asurion" /* Asurion */,
          presentationIdentifier: "affiliate" /* Affiliate */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "AHP-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "29.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  HOMEPLUS3000: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "asurionhome+" /* AsurionHome */,
        productOfferPageRoute: "asurion-home-plus/offer",
        productSelectCardConfig: {
          price: "$34.99/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-ASU-ASP-HOMEPLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-ASU-ASP-HOMEPLUS"
        }
      },
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "asurion" /* Asurion */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "D2C_HOMEPLUS-ASURIONSALESPORTAL-PORTAL-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "34.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  HOMEPLUSENT: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "asurion" /* Asurion */,
        productOfferPageRoute: "home-plus-entertainment/offer",
        productSelectCardConfig: {},
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "",
          // TODO
          ["Prod" /* Prod */]: ""
          // TODO
        }
      },
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-entertainment" /* HomeEntertainment */,
          partnerIdentifier: "asurion" /* Asurion */,
          productIdentifier: "home-entertainment" /* HomeEntertainment */,
          steps: [
            {
              page: "personal-info",
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              removeProductOffer: true,
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home Entertainment",
    price: "14.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  HOMEPRO: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        customerSessionPartner: "att" /* ATT */,
        productOfferPageRoute: "htp/offer",
        productSelectCardConfig: {
          price: "$25/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-ATT-ASP-HTP",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-ATT-ASP-HTP"
        }
      },
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-tech-protect" /* ATTHomeTech */,
          partnerIdentifier: "att" /* ATT */,
          productIdentifier: "home-tech-protect" /* ATTHomeTech */,
          steps: [
            {
              page: "account-lookup"
            },
            {
              backPage: "account-lookup",
              page: "verify-otp",
              removeProductOffer: true,
              urlParamsToRemove: ["mvid", "poid"]
            },
            {
              backPage: "account-lookup",
              page: "program-info",
              urlParamsToRemove: ["mvid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review"
            }
          ]
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    internalPartner: "ATT" /* ATT */,
    name: "AT&T HomeTech Protection",
    price: "25.00",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  MCAFEEADVANCED: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|McAfee Ultimate"
        },
        customerSessionPartner: "mcafee" /* Mcafee */,
        productOfferPageRoute: "mcafee-advanced/offer",
        productSelectCardConfig: {
          price: "See customer pricing in Customer Support Tool (CST)",
          priority: 2
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-MCF-ASP-HOME_PLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-MCF-ASP-HOME_PLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Mcafee" /* Mcafee */,
    name: "McAfee Advanced",
    price: "NA",
    productType: "PC" /* PC */,
    productVariants: ["MCAFEEULTIMATE"]
  },
  MCAFEEHOMEPLUS2: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|McAfee HomePlus"
        },
        customerSessionPartner: "mcafee" /* Mcafee */,
        productOfferPageRoute: "mcafee-home-plus/offer",
        productSelectCardConfig: {
          price: "$24.99/month",
          priority: 0
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-MCF-ASP-HOME_PLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-MCF-ASP-HOME_PLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  MCAFEEPCOPTIMIZER: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|McAfee Ultimate"
        },
        customerSessionPartner: "mcafee" /* Mcafee */,
        productOfferPageRoute: "mcafee-pc-optimizer/offer",
        productSelectCardConfig: {
          price: "See customer pricing in Customer Support Tool (CST)",
          priority: 3
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-MCF-ASP-HOME_PLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-MCF-ASP-HOME_PLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Mcafee" /* Mcafee */,
    name: "McAfee PC Optimizer",
    price: "NA",
    productType: "PC" /* PC */
  },
  MCAFEEULTIMATE: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|McAfee Ultimate"
        },
        customerSessionPartner: "mcafee" /* Mcafee */,
        productOfferPageRoute: "mcafee-ultimate/offer",
        productSelectCardConfig: {
          price: "See customer pricing in Customer Support Tool (CST)",
          priority: 1
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-MCF-ASP-HOME_PLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-MCF-ASP-HOME_PLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Mcafee" /* Mcafee */,
    name: "McAfee Ultimate",
    price: "",
    productType: "PC" /* PC */,
    productVariants: ["MCAFEEADVANCED"]
  },
  MICROSOFTHOMEPILOT: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|Asurion Home+"
        },
        customerSessionPartner: "microsoft" /* Microsoft */,
        productOfferPageRoute: "microsoft-complete-protect/offer",
        productSelectCardConfig: {
          price: "$24.99/month",
          priority: 0
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-MSF-ASP-HOMEPLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-MSF-ASP-HOMEPLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  "OPTIMUM-PPS": {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          txnComment2: "ASP|AGENT|en-US|Retail|Optimum Premier Protection & Support"
        },
        customerSessionPartner: "optimum" /* Optimum */,
        productOfferPageRoute: "optimum-premier-protection-and-support/offer",
        productSelectCardConfig: {
          price: "$19.99/month",
          priority: 0
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-OPT-ASP-PPS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-OPT-ASP-PPS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Optimum" /* Optimum */,
    name: "Premier Protection & Support",
    price: "19.99",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  PA1: {
    channelConfigs: {},
    clientChannelId: "",
    internalPartner: "ATT" /* ATT */,
    name: "",
    price: "",
    productType: "Mobility" /* Mobility */
  },
  PA4: {
    channelConfigs: {},
    clientChannelId: "",
    internalPartner: "ATT" /* ATT */,
    name: "",
    price: "",
    productType: "Mobility" /* Mobility */
  },
  PDPE: {
    assetCatalogPartner: "att-prepaid",
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "mobile-protection" /* ATTPrePaid */,
          partnerIdentifier: "att" /* ATT */,
          presentationIdentifier: "prepaid" /* PrePaid */,
          productIdentifier: "mobile-protection" /* ATTPrePaid */,
          steps: [
            {
              page: "choose-plan"
            },
            {
              backPage: "choose-plan",
              page: "account-lookup",
              removeProductOffer: true,
              urlParamsToRemove: []
            },
            {
              backPage: "account-lookup",
              page: "verify-otp",
              removeProductOffer: true,
              urlParamsToRemove: ["mvid", "poid"]
            },
            {
              backPage: "account-lookup",
              page: "personal-account-details",
              urlParamsToRemove: ["mvid", "poid"]
            },
            {
              backPage: "personal-account-details",
              page: "device-confirmation",
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "device-confirmation",
              page: "device-condition",
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "device-confirmation",
              page: "device-selection",
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "device-conditon",
              page: "payment",
              urlParamsToRemove: ["poid", "psid"]
            },
            {
              backPage: "payment",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ATTPREPAID-ASURIONSALESPORTAL-PORTAL-US",
          txnComment2: "ASP|WEB|en-US|Mobility|ATT Prepaid"
        }
      }
    },
    clientChannelId: "80375A75127456EEE053A239030AE1ED",
    internalPartner: "ATTPrepaid" /* ATTPrepaid */,
    name: "Mobile Protection for AT&T Prepaid\xAE",
    price: "14.99",
    productDetails: "Includes Mobile Insurance for AT&T Prepaid\xAE, AT&T ProTech\u2120, and AT&T Photo Storage\u2120",
    productType: "Mobility" /* Mobility */
  },
  PDPF: {
    assetCatalogPartner: "att-prepaid",
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "mobile-protection" /* ATTPrePaid */,
          partnerIdentifier: "att" /* ATT */,
          presentationIdentifier: "prepaid" /* PrePaid */,
          productIdentifier: "mobile-insurance" /* ATTMobileInsurance */,
          steps: [
            {
              page: "account-lookup",
              removeProductOffer: true,
              urlParamsToRemove: []
            },
            {
              backPage: "account-lookup",
              page: "verify-otp",
              removeProductOffer: true,
              urlParamsToRemove: ["mvid", "poid"]
            },
            {
              backPage: "account-lookup",
              page: "personal-account-details",
              urlParamsToRemove: ["mvid", "poid"]
            },
            {
              backPage: "personal-account-details",
              page: "device-confirmation",
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "device-confirmation",
              page: "device-condition",
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "device-confirmation",
              page: "device-selection",
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "device-conditon",
              page: "payment",
              urlParamsToRemove: ["poid", "psid"]
            },
            {
              backPage: "payment",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "ATTPREPAID-ASURIONSALESPORTAL-PORTAL-US",
          txnComment2: "ASP|WEB|en-US|Mobility|ATT Prepaid"
        }
      }
    },
    clientChannelId: "80375A75127456EEE053A239030AE1ED",
    internalPartner: "ATTPrepaid" /* ATTPrepaid */,
    name: "Mobile Insurance for AT&T Prepaid\xAE",
    price: "11.99",
    productDetails: "Includes phone protection",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-01": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB01",
    price: "17",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-02": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB02",
    price: "25",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-03": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB03",
    price: "35",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-04": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB04",
    price: "45",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-05": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB05",
    price: "55",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-06": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB06",
    price: "60",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-PLCY-01": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB01 Policy2",
    price: "17",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-PLCY-02": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB02 Policy2",
    price: "25",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-PLCY-03": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB03 Policy2",
    price: "35",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-PLCY-04": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB04 Policy2",
    price: "45",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-PLCY-05": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB05 Policy2",
    price: "55",
    productType: "Mobility" /* Mobility */
  },
  "PMB-12M-PLCY-06": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 12 Meses PMB06 Policy2",
    price: "60",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-01": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB01",
    price: "17",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-02": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB02",
    price: "25",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-03": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB03",
    price: "35",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-04": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB04",
    price: "45",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-05": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB05",
    price: "55",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-06": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB06",
    price: "60",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-PLCY-01": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB01 Policy2",
    price: "17",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-PLCY-02": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB02 Policy2",
    price: "25",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-PLCY-03": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB03 Policy2",
    price: "35",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-PLCY-04": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB04 Policy2",
    price: "45",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-PLCY-05": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB05 Policy2",
    price: "55",
    productType: "Mobility" /* Mobility */
  },
  "PMB-24M-PLCY-06": {
    assetCatalogPartner: "claro-brazil",
    channelConfigs: {},
    clientChannelId: "8A98270765E42C92E0535E38060A79AC",
    currency: "BRL",
    internalPartner: "Claro Brazil" /* ClaroBrazil */,
    name: "Claro Brasil Prote\xE7\xE3o M\xF3vel 24 Meses PMB06 Policy2",
    price: "60",
    productType: "Mobility" /* Mobility */
  },
  REALPAGERENTERSTECH: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "renter-tech-protect" /* RenterTechProtect */,
          partnerIdentifier: "real-page" /* RealPage */,
          presentationIdentifier: "affiliate" /* Affiliate */,
          productIdentifier: "renter-tech-protect" /* RenterTechProtect */,
          steps: [
            {
              page: "personal-account-details"
            },
            {
              backPage: "personal-account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid", "psid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "RENTERS_TECH_PROTECTION_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion Renter Tech Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Real Page Renters Tech Protect",
    price: "15.00",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  REALPAGETECHSUPPORT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "renter-tech-support" /* RenterTechSupport */,
          partnerIdentifier: "real-page" /* RealPage */,
          presentationIdentifier: "affiliate" /* Affiliate */,
          productIdentifier: "renter-tech-support" /* RenterTechSupport */,
          steps: [
            {
              page: "personal-account-details"
            },
            {
              backPage: "personal-account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid", "psid"]
            },
            {
              backPage: "program-info",
              page: "payment",
              urlParamsToRemove: []
            },
            {
              backPage: "payment",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "RENTERS_TECH_PROTECTION_HOMEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion Renter Tech Support"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Real Page Renters Tech Support",
    price: "8.00",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  STAPLESHOMEPLUS: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        billingConfig: {
          billingProgramId: "AHP-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        },
        customerSessionPartner: "staples" /* Staples */,
        productOfferPageRoute: "staples-home-plus/offer",
        productSelectCardConfig: {
          price: "$25/month"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-STP-ASP-HOMEPLUS",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-STP-ASP-HOMEPLUS"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productType: "ConnectedHome" /* ConnectedHome */,
    promoConfig: {
      durationMonths: "3",
      price: "19.99"
    }
  },
  VIUAPPLIANCEPROTECT: {
    // cloned from Comparion appliance protect
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "appliance-protect" /* ApplianceProtect */,
          partnerIdentifier: "viu" /* VIU */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "appliance-protect" /* ApplianceProtect */,
          steps: [
            {
              page: "personal-info"
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              urlParamsToRemove: ["poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              urlParamsToRemove: ["psid"]
            },
            {
              backPage: "account-details",
              page: "review"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "COMPARION_APPLIANCEPLUS-ASURIONSALESPORTAL-AGENT-US",
          txnComment2: "ASP|WEB|en-US|Retail|Asurion Appliance Protect"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    disallowedStates: ["AZ", "NV", "CA", "FL"],
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Appliance Protect",
    price: "34.99",
    productSkuOverride: "COMPARIONAPPPROTECT",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  VIUHOMEPLUSDIRECT: {
    channelConfigs: {
      ["DirectToConsumer" /* DirectToConsumer */]: {
        appRouteConfig: {
          basePath: "home-plus" /* HomePlus */,
          partnerIdentifier: "viu" /* VIU */,
          presentationIdentifier: "app" /* App */,
          productIdentifier: "home-plus" /* HomePlus */,
          steps: [
            {
              page: "personal-info",
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid"]
            },
            {
              backPage: "personal-info",
              page: "account-details",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["csid", "poid"]
            },
            {
              backPage: "account-details",
              page: "payment",
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "account-details",
              page: "program-info",
              removeProductOffer: true,
              requiredUrlParams: ["a_id"],
              urlParamsToRemove: ["psid", "poid"]
            },
            {
              backPage: "program-info",
              page: "review",
              requiredUrlParams: ["a_id"]
            },
            {
              backPage: "payment",
              page: "confirmation"
            },
            {
              backPage: "review",
              page: "confirmation"
            }
          ]
        },
        billingConfig: {
          billingProgramId: "AHP-HORIZON-AGENT-US",
          //Todo: Need to update billing program id
          txnComment2: "ASP|WEB|en-US|Retail|Asurion HomePlus"
        }
      }
    },
    clientChannelId: "8A725682288C1B27E0538A1F080A66D6",
    displayMonthlyPrice: true,
    internalPartner: "Asurion" /* Asurion */,
    name: "Asurion Home+",
    price: "24.99",
    productSkuOverride: "ACRISUREHOMEPLUS",
    productType: "ConnectedHome" /* ConnectedHome */
  },
  VZN_AAL: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        customerSessionPartner: "verizon" /* Verizon */,
        productOfferPageRoute: "vzn-aal/offer",
        productSelectCardConfig: {},
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-VZW-ASP-AAL",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-VZW-ASP-AAL"
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon Add-a-line Services",
    price: "25.00",
    productType: "Mobility" /* Mobility */
  },
  VZN_ACC: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        customerSessionPartner: "verizon" /* Verizon */,
        productOfferPageRoute: "vzn-acc/offer",
        productSelectCardConfig: {
          price: "Starting at $4.99"
        },
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-VZW-ASP-ACC",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-VZW-ASP-ACC"
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon Phone Accessories",
    price: "25.00",
    productType: "Accessories" /* Accessories */
  },
  VZN_UPGRADE: {
    channelConfigs: {},
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon Upgrade",
    price: "",
    productType: "Mobility" /* Mobility */
  },
  VZN_VMPMD: {
    channelConfigs: {
      ["Voice" /* Voice */]: {
        customerSessionPartner: "verizon" /* Verizon */,
        productOfferPageRoute: "vzn-vmpmd/eligibility",
        productSelectCardConfig: {},
        requiredADGroup: {
          ["NonProd" /* NonProd */]: "HQDOMAIN_APP-DEV-VZW-ASP-VMP",
          ["Prod" /* Prod */]: "HQDOMAIN_APP-PR-VZW-ASP-VMP"
        }
      }
    },
    clientChannelId: "C10D49A78FF26637E0539B39030AA612",
    internalPartner: "Verizon" /* Verizon */,
    name: "Verizon Device Protection (Except WPP)",
    price: "25.00",
    productType: "Mobility" /* Mobility */
  }
};

// src/accessors/get-asset-catalog-configs.ts
function getAssetCatalogConfigs() {
  return Object.values(PRODUCT_CONFIG_MAP).filter(
    (config) => config.assetCatalogPartner != null && config.assetCatalogPartner !== "" && config.clientChannelId !== ""
  ).reduce(
    (acc, { assetCatalogPartner, clientChannelId }) => {
      return acc.find(
        (config) => config.partner === assetCatalogPartner && config.clientChannelId === clientChannelId
      ) ? acc : [...acc, { partner: assetCatalogPartner, clientChannelId }];
    },
    []
  );
}

// src/accessors/get-product-config.ts
function getProductConfig(productSKU) {
  const productConfig = PRODUCT_CONFIG_MAP[productSKU];
  if (productConfig == null) {
    throw new Error(`No product config found for product SKU: ${productSKU}`);
  }
  return productConfig;
}

// src/accessors/get-billing-config.ts
function getBillingConfig(productSKU, channel) {
  const productConfig = getProductConfig(productSKU);
  return productConfig.channelConfigs[channel]?.billingConfig ?? null;
}

// ../invariant/dist/index.mjs
var __require2 = /* @__PURE__ */ ((x) => typeof __require !== "undefined" ? __require : typeof Proxy !== "undefined" ? new Proxy(x, {
  get: (a, b) => (typeof __require !== "undefined" ? __require : a)[b]
}) : x)(function(x) {
  if (typeof __require !== "undefined")
    return __require.apply(this, arguments);
  throw Error('Dynamic require of "' + x + '" is not supported');
});
var prefix = "Invariant failed";
var InvariantError = class extends Error {
  constructor(message, context, metadata) {
    super(message ? `${prefix}: ${message}` : prefix);
    this.name = "InvariantError";
    this.context = context;
    this.metadata = metadata;
  }
};
var bugsnag;
try {
  bugsnag = __require2("@bugsnag/js").default;
} catch {
}
function bugsnagInvariantHandler(error) {
  if (bugsnag?.isStarted()) {
    bugsnag.notify(error, notifyCallback(error));
  }
}
function notifyCallback(error) {
  return (event) => {
    if (error instanceof InvariantError) {
      event.severity = "error";
    }
    if (error.context) {
      event.context = error.context;
    }
    if (error.metadata) {
      event.addMetadata("invariant", error.metadata);
    }
  };
}
function consoleInvariantHandler(error) {
  if (process.env.NODE_ENV !== "test") {
    console.error(error);
  }
}
var invariantHandlers = [
  consoleInvariantHandler,
  bugsnagInvariantHandler
];
function invariant(condition, message, options) {
  if (condition) {
    return;
  }
  const error = new InvariantError(
    message,
    options?.context,
    options?.metadata
  );
  Error.captureStackTrace(error, invariant);
  for (const handler of invariantHandlers) {
    handler(error);
  }
  throw error;
}

// src/accessors/get-client-channel-id-for-internal-partner.ts
function getClientChannelIdForInternalPartner(internalPartner) {
  const clientChannelId = Object.values(PRODUCT_CONFIG_MAP).find(
    (config) => config.internalPartner === internalPartner
  )?.clientChannelId;
  invariant(
    clientChannelId,
    `No clientChannelId found for partner ${internalPartner}`
  );
  return clientChannelId;
}

// src/types/app-config-types/asp-product-config-types.ts
var Service = /* @__PURE__ */ ((Service2) => {
  Service2["AmpCommunication"] = "amp-communication";
  Service2["AsurionEligibility"] = "asurion-eligibility";
  Service2["AsurionEnrollment"] = "asurion-enrollment";
  Service2["BillingDiscounts"] = "billing-discounts";
  Service2["PaySession"] = "pay-session";
  return Service2;
})(Service || {});
var FeatureFlag = /* @__PURE__ */ ((FeatureFlag2) => {
  FeatureFlag2["UseSmartLink"] = "use-smart-link";
  return FeatureFlag2;
})(FeatureFlag || {});

// src/accessors/get-customer-session-partner-for-product-sku.ts
function getCustomerSessionPartnerForProductSKU(productSKU) {
  const config = PRODUCT_CONFIG_MAP[productSKU];
  if (config == null) {
    throw new Error(`Unknown product SKU: ${productSKU}`);
  }
  invariant(config.channelConfigs["Voice" /* Voice */], "Voice channel is required");
  return config.channelConfigs["Voice" /* Voice */].customerSessionPartner;
}

// src/accessors/get-customer-session-partners.ts
function getCustomerSessionPartners(adGroups = [], environment) {
  return Array.from(
    new Set(
      Object.entries(PRODUCT_CONFIG_MAP).filter(([, config]) => {
        const requiredADGroup = config.channelConfigs["Voice" /* Voice */]?.requiredADGroup[environment];
        return requiredADGroup ? adGroups.includes(requiredADGroup) : false;
      }).map(
        ([, config]) => config.channelConfigs["Voice" /* Voice */].customerSessionPartner
      )
    )
  );
}

// src/accessors/get-internal-partner-for-product-sku.ts
function getInternalPartnerForProductSKU(productSKU) {
  const config = PRODUCT_CONFIG_MAP[productSKU];
  if (config == null) {
    throw new Error(`Unknown product SKU: ${productSKU}`);
  }
  return config.internalPartner;
}

// src/accessors/get-product-config-for-d2c-app-route.ts
function getProductConfigForD2CAppRoute(basePath, partnerIdentifier, productIdentifier) {
  const basePathMatches = Object.entries(PRODUCT_CONFIG_MAP).filter((entry) => {
    const [, config] = entry;
    return config.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.basePath === basePath;
  });
  if (basePathMatches.length === 0) {
    throw new Error(`No product config found for base path: ${basePath}`);
  }
  if (basePathMatches.length === 1) {
    const [productSku, config] = basePathMatches[0];
    return { ...config, productSku: config.productSkuOverride ?? productSku };
  }
  if (!partnerIdentifier) {
    throw new Error(
      "Partner identifier is required when multiple products match the base path."
    );
  }
  const result = basePathMatches.filter((entry) => {
    const [, config] = entry;
    return config.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.partnerIdentifier === partnerIdentifier;
  });
  if (result == null || result.length === 0) {
    throw new Error(
      `No product config found for base path: ${basePath} and partner identifier: ${partnerIdentifier}`
    );
  }
  if (result.length === 1) {
    const [productSku, config] = result[0];
    return { ...config, productSku: config.productSkuOverride ?? productSku };
  }
  const targetMatch = result.find((entry) => {
    const [, config] = entry;
    return config?.channelConfigs?.DirectToConsumer?.appRouteConfig?.productIdentifier?.toLowerCase() === productIdentifier?.toLowerCase();
  });
  if (targetMatch) {
    const [productSku, config] = targetMatch;
    return { ...config, productSku: config.productSkuOverride ?? productSku };
  }
  throw new Error(
    `Cannot find target config based on productIdentifier: ${productIdentifier}`
  );
}

// src/accessors/get-product-config-for-presentation-routes.ts
function getProductConfigForPresentationRoutes(productIdentifier, partnerIdentifier, presentationIdentifier) {
  const productMatches = Object.entries(PRODUCT_CONFIG_MAP).filter((entry) => {
    const [, config2] = entry;
    return config2.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.productIdentifier === productIdentifier && config2.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.partnerIdentifier === partnerIdentifier;
  });
  if (productMatches.length === 0) {
    throw new Error(
      `No product config found for product path: ${productIdentifier}`
    );
  }
  if (productMatches.length === 1) {
    const [productSku2, config2] = productMatches[0];
    return { ...config2, productSku: config2.productSkuOverride ?? productSku2 };
  }
  if (!presentationIdentifier) {
    throw new Error(
      "Presentation identifier is required when multiple products match the product and partner path"
    );
  }
  const result = productMatches.find((entry) => {
    const [, config2] = entry;
    return config2.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.presentationIdentifier === presentationIdentifier;
  });
  if (result == null) {
    throw new Error(
      `No product config found for product path: ${productIdentifier} partner identifier: ${partnerIdentifier} and presentation identifier: ${presentationIdentifier} `
    );
  }
  const [productSku, config] = result;
  return { ...config, productSku: config.productSkuOverride ?? productSku };
}

// src/accessors/get-product-configs-for-presentation-route-by-partner.ts
function getProductConfigsForPresentationRouteByPartner(partnerIdentifier, presentationIdentifier) {
  const results = [];
  if (!presentationIdentifier || !partnerIdentifier) {
    let missingIdentifier;
    if (!presentationIdentifier) {
      missingIdentifier = "Presentation";
    } else {
      missingIdentifier = "Partner";
    }
    const message = `${missingIdentifier}Identifier not provided.`;
    throw new Error(message);
  }
  const productMatches = Object.entries(PRODUCT_CONFIG_MAP).filter((entry) => {
    const [, config] = entry;
    return config.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.presentationIdentifier === presentationIdentifier && config.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.partnerIdentifier === partnerIdentifier;
  });
  if (productMatches.length === 0) {
    throw new Error(
      `No product config found for  partner identifier: ${partnerIdentifier} and presentation identifier: ${presentationIdentifier} `
    );
  } else {
    productMatches.map((entry) => {
      const [productSku, config] = entry;
      const result = {
        ...config,
        productSku: config.productSkuOverride ?? productSku
      };
      results.push({ ...result });
    });
  }
  return results;
}

// src/accessors/get-product-skus-for-ad-groups-and-environment.ts
function getProductSKUsForADGroupsAndEnvironment(adGroups = [], environment, eligibilityStrategies) {
  return Object.entries(PRODUCT_CONFIG_MAP).filter(([, config]) => {
    const requiredADGroup = config.channelConfigs["Voice" /* Voice */]?.requiredADGroup[environment];
    const adGroupMatch = requiredADGroup ? adGroups.includes(requiredADGroup) : false;
    const eligibilityStrategyMatch = eligibilityStrategies && config.eligibilityStrategy ? eligibilityStrategies.includes(config.eligibilityStrategy) : true;
    return adGroupMatch && eligibilityStrategyMatch;
  }).map(([productSKU]) => productSKU);
}

// src/accessors/get-product-skus-for-customer-session-partner.ts
function getProductSKUsForCustomerSessionPartner(customerSessionPartner, productTypes, eligibilityStrategies) {
  return Object.entries(PRODUCT_CONFIG_MAP).filter(([, config]) => {
    const matchesPartnerAndProductType = (config.channelConfigs["Voice" /* Voice */]?.customerSessionPartner === customerSessionPartner && productTypes?.includes(config.productType)) ?? true;
    const eligibilityStrategyMatch = eligibilityStrategies && config.eligibilityStrategy ? eligibilityStrategies.includes(config.eligibilityStrategy) : true;
    return matchesPartnerAndProductType && eligibilityStrategyMatch;
  }).map(([productSKU]) => productSKU);
}

// src/accessors/get-product-skus-for-internal-partner.ts
function getProductSKUsForInternalPartner(internalPartner, productTypes) {
  return Object.entries(PRODUCT_CONFIG_MAP).filter(([_productSKU, config]) => {
    const internalPartnerMatch = Array.isArray(internalPartner) ? internalPartner.includes(config.internalPartner) : internalPartner === config.internalPartner;
    return (internalPartnerMatch && productTypes?.includes(config.productType)) ?? true;
  }).map(([productSKU, _config]) => productSKU);
}

// src/accessors/get-product-variants-for-sku.ts
function getProductVariantsForSku(sku) {
  return PRODUCT_CONFIG_MAP[sku]?.productVariants ?? [];
}

// src/accessors/get-step-index.ts
function getStepIndex(stepsArray, pageName) {
  return stepsArray.findIndex((pageInfo) => pageInfo.page === pageName);
}

// src/accessors/get-step-info.ts
function getStepInfo(stepsArray, pageName) {
  return stepsArray.find((pageInfo) => pageInfo.page === pageName);
}

// src/accessors/get-step-info-for-presentation-routes.ts
function getStepInfoForPresentationRoutes(productIdentifier, partnerIdentifier, presentationIdentifier, page) {
  const productConfig = getProductConfigForPresentationRoutes(
    productIdentifier,
    partnerIdentifier,
    presentationIdentifier
  );
  const steps = productConfig.channelConfigs["DirectToConsumer" /* DirectToConsumer */]?.appRouteConfig.steps;
  if (steps)
    return getStepInfo(steps, page);
}

// src/accessors/get-step-names.ts
function getStepNames(stepsArray) {
  return stepsArray.map((pageInfo) => pageInfo.page);
}

// src/accessors/get-voice-ad-group.ts
function getVoiceADGroup(productSKU, environment) {
  const productConfig = getProductConfig(productSKU);
  const voiceChannelConfig = productConfig.channelConfigs["Voice" /* Voice */];
  if (voiceChannelConfig == null) {
    throw new Error(
      `Product SKU: ${productSKU} is not sold in the Voice channel.`
    );
  }
  const adGroup = voiceChannelConfig.requiredADGroup[environment];
  if (adGroup == null) {
    throw new Error(
      `No AD group configured for product SKU: ${productSKU} in environment: ${environment}`
    );
  }
  return adGroup;
}

// src/accessors/get-voice-channel-config.ts
function getVoiceChannelConfig(productSKU) {
  const productConfig = getProductConfig(productSKU);
  return productConfig.channelConfigs["Voice"] ?? null;
}

// src/models/billing-discounts-config.ts
var DiscountType = /* @__PURE__ */ ((DiscountType2) => {
  DiscountType2["ExitSave"] = "EXIT_SAVE";
  DiscountType2["Promotion"] = "PROMOTION";
  return DiscountType2;
})(DiscountType || {});
var DiscountActionType = /* @__PURE__ */ ((DiscountActionType2) => {
  DiscountActionType2["FlatAmount"] = "FLAT_AMOUNT";
  DiscountActionType2["Percentage"] = "PERCENTAGE";
  return DiscountActionType2;
})(DiscountActionType || {});
var RedemptionType = /* @__PURE__ */ ((RedemptionType2) => {
  RedemptionType2["Limited"] = "LIMITED";
  return RedemptionType2;
})(RedemptionType || {});
var DiscountStatus = /* @__PURE__ */ ((DiscountStatus2) => {
  DiscountStatus2["Active"] = "ACTIVE";
  return DiscountStatus2;
})(DiscountStatus || {});
var InvoiceProfileStatus = /* @__PURE__ */ ((InvoiceProfileStatus2) => {
  InvoiceProfileStatus2["Active"] = "ACTIVE";
  return InvoiceProfileStatus2;
})(InvoiceProfileStatus || {});

// src/models/pay-session-config.ts
var SourceApp = /* @__PURE__ */ ((SourceApp2) => {
  SourceApp2["D2cSalesTool"] = "d2c-sales-tool";
  SourceApp2["SalesTool"] = "sales-tool";
  return SourceApp2;
})(SourceApp || {});
export {
  BasePath,
  Channel,
  CustomerSessionPartner,
  DiscountActionType,
  DiscountStatus,
  DiscountType,
  EligibilityStrategy,
  Environment,
  FeatureFlag,
  InternalPartner,
  InvoiceProfileStatus,
  PartnerIdentifier,
  PresentationIdentifier,
  ProductIdentifier,
  ProductType,
  RedemptionType,
  Service,
  SourceApp,
  getAssetCatalogConfigs,
  getBillingConfig,
  getClientChannelIdForInternalPartner,
  getCustomerSessionPartnerForProductSKU,
  getCustomerSessionPartners,
  getInternalPartnerForProductSKU,
  getProductConfig,
  getProductConfigForD2CAppRoute,
  getProductConfigForPresentationRoutes,
  getProductConfigsForPresentationRouteByPartner,
  getProductSKUsForADGroupsAndEnvironment,
  getProductSKUsForCustomerSessionPartner,
  getProductSKUsForInternalPartner,
  getProductVariantsForSku,
  getStepIndex,
  getStepInfo,
  getStepInfoForPresentationRoutes,
  getStepNames,
  getVoiceADGroup,
  getVoiceChannelConfig
};
