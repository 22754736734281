export type CommonRebuttals = {
  [key: string]: string;
};

export type SelectOption = {
  value: string;
  displayText: string;
  rebuttals: string;
};

export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const MONTHS = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
};

export const CallTypes = [
  {
    name: 'McAfee Assist +',
    value: 'Assist',
  },
  {
    name: 'McAfee Tech Support',
    value: 'Tech Support',
  },
  {
    name: 'Ghost Call',
    value: 'Ghost',
  },
  {
    name: 'Transfer',
    value: 'Transfer',
  },
  {
    name: 'Other',
    value: 'Other',
  },
];

export const PROFILE_ROLES_MAP = new Map<string, string>([
  ['HQDOMAIN_APP-DEV-SSO-ASP-EMPLOYEE', 'employee'],
  ['HQDOMAIN_APP-PR-SSO-ASP-EMPLOYEE', 'employee'],
  ['HQDOMAIN_APP-DEV-SSO-ASP-SUPERVISOR', 'supervisor'],
  ['HQDOMAIN_APP-PR-SSO-ASP-SUPERVISOR', 'supervisor'],
]);

export const FULLSTORY_ORG_ID = 'ZVSN6';

export const COMMON_REBUTTALS: CommonRebuttals = {
  budget:
    'Reply: “You could save the cost of paying for multiple warranties on future purchases and repairs.”',
  busy: 'Reply: "What if I told you that this plan could save you time and money. With hassle-free claims, you never have to stress about getting your tech repaired."',
  consult:
    'Reply: "Enrolling in this product is not a contract, and you can cancel anytime. We can help sign you up now and share our FAQs so that you can discuss them later."',
  similarProduct:
    'Reply: "With this plan you’ll only have one warranty that covers all your favorite home tech along with hassle-free coverage — no receipts or registration required to enroll."',
};

export const COMMON_TEMPLATE_VARIABLES = {
  PROGRAM_NAME: 'Asurion Home+',
  BILLED_AMOUNT: '24.99',
};

export const VHDP_REBUTTALS = {
  ...COMMON_REBUTTALS,
  consult: '',
};

export const HTP_REBUTTALS = {
  ...COMMON_REBUTTALS,
  consult: '',
};

export const DECLINE_OPTIONS: SelectOption[] = [
  {
    value: 'budget',
    displayText: 'Budget',
    rebuttals: 'budget',
  },
  {
    value: 'busy',
    displayText: 'Busy',
    rebuttals: 'busy',
  },
  {
    value: 'consult',
    displayText: 'Consult',
    rebuttals: 'consult',
  },
  {
    value: 'already-has-a-similar-product',
    displayText: 'Already has a similar product',
    rebuttals: 'similarProduct',
  },
];

export const MCAFEE_INELIGIBLE_OPTIONS: SelectOption[] = [
  {
    value: 'ineligible',
    displayText: `Ineligible McAfee affiliate`,
    rebuttals: 'ineligible',
  },
  {
    value: 'unresolved-issue',
    displayText: 'Unresolved issue',
    rebuttals: 'unresolved-issue',
  },
  {
    value: 'vulnerable-customer',
    displayText: 'Vulnerable customer',
    rebuttals: 'vulnerable-customer',
  },
];

export const OPTIMUM_INELIGIBLE_OPTIONS: SelectOption[] = [
  {
    value: 'ineligible',
    displayText: `Ineligible Optimum affiliate`,
    rebuttals: 'ineligible',
  },
  {
    value: 'unresolved-issue',
    displayText: 'Unresolved issue',
    rebuttals: 'unresolved-issue',
  },
  {
    value: 'vulnerable-customer',
    displayText: 'Vulnerable customer',
    rebuttals: 'vulnerable-customer',
  },
];
export const VZ_5G_REBUTTALS = {
  budget:
    'Reply: “With Verizon 5G you’ll get faster connection speeds and you’ll save money since it costs less than most Internet plans.”',
  busy: 'Reply: "You’ll save time with faster Internet speeds, making it easier to browse, download, and stream on your connected devices."',
  consult: '',
  similarProduct:
    'Reply: "Verizon 5G provides faster connection speeds than most Internet providers."',
};

export const CRICKET_REBUTTALS = { ...COMMON_REBUTTALS };
export const MCAFEE_REBUTTALS = { ...COMMON_REBUTTALS };
export const APPLIANCE_PLUS_REBUTTALS = { ...COMMON_REBUTTALS };
export const HOME_PLUS_REBUTTALS = { ...COMMON_REBUTTALS };
export const STAPLES_HOME_PLUS_REBUTTALS = { ...COMMON_REBUTTALS };
export const MICROSOFT_REBUTTALS = { ...COMMON_REBUTTALS };
export const OPTIMUM_REBUTTALS = { ...COMMON_REBUTTALS };
export const AMAZON_REBUTTALS = { ...COMMON_REBUTTALS };

export const CRICKET_AMP_TEMPLATE_VARIABLES = { ...COMMON_TEMPLATE_VARIABLES };
export const MCAFEE_HOME_PLUS_AMP_TEMPLATE_VARIABLES = {
  ...COMMON_TEMPLATE_VARIABLES,
};
export const HOME_PLUS_AMP_TEMPLATE_VARIABLES = {
  ...COMMON_TEMPLATE_VARIABLES,
};
export const MICROSOFT_AMP_TEMPLATE_VARIABLES = {
  PROGRAM_NAME: 'Microsoft Complete Home Protect',
  BILLED_AMOUNT: '24.99',
};

export const AMAZON_AMP_TEMPLATE_VARIABLES = {
  PROGRAM_NAME: 'Asurion Complete Home Protect',
  BILLED_AMOUNT: '16.99',
  REDIRECT_URL: 'https://asur.me/zmtrcl',
};

export const APPLIANCE_PLUS_AMP_TEMPLATE_VARIABLES = {
  PROGRAM_NAME: 'Asurion Appliance+',
  BILLED_AMOUNT: '34.99',
};

export const OPTIMUM_AMP_TEMPLATE_VARIABLES = {
  PROGRAM_NAME: 'Optimum Premier Protection & Support',
  BILLED_AMOUNT: '24.99',
};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export type Option = {
  name: string;
  value: string;
};

export const locales = [
  { label: 'English', value: 'en-us', default: true },
  { label: 'Spanish', value: 'es-us' },
];

export const addressSearchClient = [
  'mcafee',
  'staples',
  'microsoft',
  'google',
  'asurionhome+',
];
